<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center"  style="    margin-top: 10px;">
          <dv-decoration-10 style="width: 23.3%; height: 0.0625rem" />
          <div class="d-flex jc-center" style="width: 53.3%">
            <dv-decoration-8
              :color="['#568aea', '#000000']"
              style="width: 1.5rem; height: 0.625rem"
            />
            <div class="title" style="width: 10rem">
              <span
                class="title-text"
                style="font-size: 44px; position: initial"
                >旭升帽业原料仓库中心</span
              >
              <!-- <dv-decoration-6
                class="title-bototm"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
                style="width:3.125rem;height:.1rem;"
              /> -->
            </div>
            <dv-decoration-8
              :reverse="true"
              :color="['#568aea', '#000000']"
              style="width: 1.5rem; height: 0.625rem"
            />
          </div>
          <dv-decoration-10
            style="width: 23.3%; height: 0.0625rem; transform: rotateY(180deg)"
          />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex" style="width: 60%">
            <div
              class="react-left mr-4"
              style="width: 100%; background-color: #0f1325; text-align: center;height: 0.825rem;
              line-height: 0.825rem;"
            >
              <span class="react-after"></span>
              <span
                class="text"
                style="font-size: xxx-large; color: springgreen; width: 100%"
              >
                <vue-seamless-scroll
                  :data="newsList"
                  :class-option="optionLeft"
                  class="seamless-warp2"
                >
                  <ul
                    class="item"
                    style="
                      font-size: xxx-large;
                      font-style: normal;
                      color: #0fd33a;
                    "
                  >
                    <li style="font-size: xxx-large; font-style: normal"  v-html="scrollshow">
                    </li>
                  </ul>
                </vue-seamless-scroll></span
              >
            </div>

            <!-- <div class="react-left bg-color-blue mr-3" style="width:100%;">
    <vue-seamless-scroll :data="newsList" :class-option="optionLeft" class="seamless-warp2">
        <ul class="item"  style="font-size:xx-large;font-style: normal;color: red;">
            <li style="font-size:xx-large;font-style: normal;">
              你有13个待配料制单
            </li>
        </ul>
    </vue-seamless-scroll>
            </div> -->
          </div>
          <div style="width: 40%" class="d-flex">
            <div
              class="react-left mr-4"
              style="
                width: 6.25rem;
                background-color: #0f1325;
                text-align: right;
              "
            >
              <span class="react-after"></span>
              <span class="text" style="font-size: xx-large; color: springgreen"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="center-box">
            <dv-border-box-13>
              <topLeft />
            </dv-border-box-13>
          </div>
          <div class="bototm-box">
            <dv-border-box-12>
              <topRight />
            </dv-border-box-12>
            <dv-border-box-12>
              <bottomRight />
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import {} from "../libs/axios";
import { formatTime } from "../utils/index.js";
import topLeft from "./ctware/topLeft";
import topRight from "./ctware/topRight";
import bottomRight from "./ctware/bottomRight";
import {getWareNoBatchingNum} from '../libs/axios';
export default {
  data() {
    return {
      selectList: [], // 多选数据
      loading: true,
      dateDay: null,
      showdiv: "0",
      dateYear: null,
      dateWeek: null,
      scrollshow:"",
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      newsList: [
        {
          title: "A simple, seamless scrolling for Vue.js",
        },
        {
          title: "A curated list of awesome things related to Vue.js",
        },
      ],
      // 添加或编辑表单对象初始化数据
      lineGuids: "",
      workShopName: "",
    };
  },
  components: {
    topLeft,
    // centerRightDown1,
    topRight,
    bottomRight,
  },
  mounted() {
    this.init();
    this.timeFn();
    this.cancelLoading();
    this.changediv1();
    // this.audioPlayer();
    setInterval(this.init,120000);
  },
  methods: {
    init() {this.getWageList();},
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
     getWageList() {
      this.loading = true;
      getWareNoBatchingNum().then((res) => {
          this.loading = false;
          var batchNum=res.data[0];
          if(batchNum>0)
          {
            this.scrollshow="你有<span style='color: red; font-size: 62px; font-weight: 900'>"+res.data[0]+"</span>个待配料制单";
          }
          else
          {
            this.scrollshow="暂无领料单信息";
          }
          // this.$set(this.config,  this.config.data,res.data);
          // this.config.data=res.data;
      });
    },

    changediv1() {
      setInterval(() => {
        if (this.$store.state.divshow == "0") {
          this.showdiv = "1";
          this.$store.commit("setDivshow", "1");
        } else {
          this.showdiv = "0";
          this.$store.commit("setDivshow", "0");
        }
      }, 30000);
    },
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 1,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/ctware.scss";
</style>
<style lang="scss" scoped>
.seamless-warp2 {
  overflow: hidden;
  height: 100%;
  width: 100%;

}
ul.item {
    width: 800px;

  }
  li {
      float: left;
      margin-right: 100px;
    }
</style>