<template>
  <div id="toLeft">
    <div class="bg-color-blacktopleft">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">计划领料状态</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box"  style="height:90%; font-size: 0.5rem;color:#ffc103" >
        <dv-scroll-board :config="config"  />
      </div>
    </div>
  </div>
</template>

<script>
import {findPlanWareOutList} from '../../libs/axios';
export default {
  data() {
    return {
      config: {
        rowNum: 3, //表格行数
        header:  ["当前状态", "订单编码","出料类型", "出料单号",  "领取人", "制单时间"],
        data:[],
        headerHeight: 65,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true, 
        columnWidth: [80,220,260,220,280,220,460],
        align: ["center"]
      }
    };
  },
  components: {},
  mounted() {
    this.init();
     setInterval(this.init,120000);
  },
  methods: {
    init() {
      this.getWageList();
    },
     getWageList() {
      this.loading = true;
      findPlanWareOutList().then((res) => {
          this.loading = false;
          this.$set(this.config,  this.config.data,res.data);
          this.config.data=res.data;
      });
    },
  }
};
</script>

<style lang="scss">
#toLeft {
  padding: 0.2rem;
  height: 6.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  font-size: 0.7rem;
  }
  .bg-color-blacktopleft {
    height: 6.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    font-size: 0.8rem;
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
    font-size: 1rem;
  }
  .header-item {
    font-size: 0.6rem;
    text-align: center;
  }
  .row-item {
    font-size: 0.5rem;
    color: "#ffc103";
  }
  .ceil {
    font-size: 0.6rem;
    color: #03ebfc;
    text-align: center;
  }
.pt-2{
display: none;
}
</style>